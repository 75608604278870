<template>
  <v-app>
     <v-banner single-line class="banner h5-carousel-new">
      <v-img
        src="https://h5.ophyer.cn/official_website/banner/num-banner-h5-saas.png"
        height="100%"
        :transition="false"
        class="d-flex align-center"
        
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <div class="h5-banner-info" style="margin-top: -40px;">
        <div class="width-per-100">
          <div class="h5-banner-title">元宇宙UGC工具</div>
        </div>
      </div>
    </v-banner>
    <!-- 你的元宇宙从强大的工具开始 -->
    <v-card class="px-4 py-6 h5model_list3" flat>
      <v-card-title class="justify-center font-size-20 pa-0 line-height-28">你的元宇宙从强大的工具开始</v-card-title>
      <div class="mt-4">
        <v-row>
          <v-col v-for="(v,rowIndex) in modelList1" :key="rowIndex" cols="6" style="padding: 10px">
            <v-card class="pa-0 d-flex h5-card" min-height="90" width="100%" flat>
              <div class="model_item" style="min-height:120px;">
                <img class="item_img" :src='v.icon' alt style="width:70px;height:70px;padding-top:5px;" />
                <div class="item_info" style="margin-top: 4px;">
                  <v-card-title class="justify-center reset-title font-size-16">{{ v.title }}</v-card-title>
                  <!-- <v-card-text class="reset-text">{{ v.text }}</v-card-text> -->
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- 产品体系 -->
    <v-card color="#2181EA" flat class="pt-2 pb-6 position-r">
      <v-tabs v-model="tab" background-color="transparent" color="white" dark
              class="xs-tab" style="margin-top:0px;">
        <v-tab v-for="(v, k) in data.wrap2.list" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === data.wrap2.list.length - 1 ? 'mr-5':'']"
               style="min-width: unset">
          <b class="color-FFF font-size-16 letter-spacing" style="font-weight:400">{{ v.title }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-5" v-model="tab" style="border-radius: 10px;">
        <v-tab-item v-for="(v, k) in data.wrap2.list" :key="k" :transition="false">
          <v-card flat color="white" class="pa-5">
            <v-img :src="v.img" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
            <v-card-title class="pa-0 mt-4 font-size-18 line-height-25">{{v.h2}}</v-card-title>
              <v-card-text class="pa-0 my-1 line-height-26"  v-text="v.content"  v-html="v.content"/>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
      <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
    </v-card>
    <!-- SaaS生态（趣味内容+丰富玩法一站式服务，丰富的AR能力，出色的VR编辑器） -->
    <v-card class="warp warp3  " :flat="true">
      <v-card-title class="justify-center font-size-20 pa-0 line-height-28 pt-6">SaaS生态</v-card-title>
      <div class="h5-public-title">SaaS ECOLOGY</div>
      <v-card-text class="text-align-center pa-0">元宇宙素材UGC平台</v-card-text>
      <div class="box">
        <div class="right">
          <img src="https://h5.ophyer.cn/official_website/other/saasshengtai.png" width="100%" contain />
        </div>
        <div class="left">
          <v-card-text class="tip">不仅可以创作元宇宙空间，你创造出的任意内容都可以在UGC平台分享</v-card-text>
        </div>
      </div>
    </v-card>
    <!-- (2)趣味内容+丰富玩法一站式服务 -->
    <!-- <v-card color="#2181EA" flat class="pb-6 position-r">
      <v-card-title class="justify-center color-FFF font-size-20 pa-0 line-height-28">趣味内容+丰富玩法一站式服务</v-card-title>
      <v-tabs v-model="tab1" background-color="transparent" color="white" dark
              class="xs-tab">
        <v-tab v-for="(v, k) in modelList3" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === modelList3 - 1 ? 'mr-5':'']"
               style="min-width: unset">
          <b class="color-FFF font-size-16 letter-spacing">{{ v.title }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-5" v-model="tab1" style="border-radius: 10px;">
        <v-tab-item v-for="(v, k) in modelList3" :key="k" :transition="false">
          <v-card flat color="white" class="pa-5">
            <v-img :src="v.img" width="80%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
            <v-card-title class="pa-0 mt-4 font-size-18 font-weight-bold line-height-25">{{v.tit}}</v-card-title>
              <v-card-text class="pa-0 my-1 line-height-26"  v-text="v.text"  v-html="v.text"/>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <div class="tab-btn-prev" @click.stop="handleTabBtnin('prev')"></div>
      <div class="tab-btn-next" @click.stop="handleTabBtnin('next')"></div>
    </v-card> -->
    <v-card class="px-4 py-6 h5model_list3" flat>
      <v-card-title class="justify-center font-size-20 pa-0 line-height-28">趣味内容+丰富玩法一站式服务</v-card-title>
      <div class="mt-3">
        <v-row>
          <v-col v-for="(v,rowIndex) in modelList3" :key="rowIndex" cols="6" style="padding: 10px">
            <v-card class="pa-0 d-flex h5-card" min-height="90" width="100%" flat>
              <div class="model_item">
                <img class="item_img" :src='v.img' alt/>
                <div class="item_info">
                  <v-card-title class="justify-center reset-title font-size-16">{{ v.title }}</v-card-title>
                  <v-card-text class="reset-text">{{v.text}}</v-card-text>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- (3)丰富的AR能力 -->
    <v-card flat class="width-per-100 pb-6" style="background: #F5F8FC;">
      <v-card-title class="justify-center font-size-20 pa-0 line-height-28 pt-6">丰富的AR能力</v-card-title>
      <div class="h5-public-title">AR ABILITY</div>
      <div class="mt-4 px-3">
        <v-row v-for="(row,rowIndex) in data.cardList" :key="rowIndex" class="px-4">
          <v-col col="6" class="d-flex justify-center"
                 style="padding: 0 5px;"
                 v-for="(col,colIndex) in row" :key="colIndex">
            <v-card flat class="pa-3 h5-card "
                    :min-height="$vuetify.breakpoint.name === 'xs'?95:78"
                    width="100%">
              <div class="d-flex justify-center">
                <v-img :src="col.img" width="48" height="48" contain></v-img>
              </div>
              <v-card-title class="pa-0 font-size-16 line-height-22 justify-center color-333 mt-2">
                {{col.title}}
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- 出色的VR编辑器 -->
    <v-card  flat class="py-6 position-r h5model_list2">
      <v-card-title class="justify-center font-size-20 pa-0 line-height-28">出色的VR编辑器</v-card-title>
      <div class="h5-public-title">VR EDITOR</div>
      <div class="px-3 mt-3">
        <v-row class="justify-center ma-0">
          <v-col v-for="(v,k) in modelList2" :key="k" cols="6">
            <div class="model_item">
              <img class="item_img" :src='v.img'/>
              <div class="item_info">
                {{ v.tit }}
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- AR打卡-沉浸式游览交互互动小程序游戏 -->
      <v-card  color="#f8faff" class="warp warp3 pt-6" :flat="true">
      <v-card-title class="justify-center font-size-20 pa-0 line-height-28">AR打卡</v-card-title>
      <div class="h5-public-title">AR PUNCH THE CLOCK</div>
      <v-card-text class="text-align-center pa-0 pb-4">沉浸式游览交互互动小程序游戏</v-card-text>
      <div class="box">
        <div class="right">
          <img src="https://h5.ophyer.cn/official_website/other/saasAR.png" width="100%" contain />
        </div>
        <div class="left">
          <v-card-title class="title">AR打卡</v-card-title>
          <v-card-text class="tip">1. 线上线下一体的沉浸式游览交互互动的小程序游戏</v-card-text>
          <v-card-text class="tip">2. 针对不同旅游人群特点为景区 设计旅游路线规划</v-card-text>
          <v-card-text class="tip">3. 促进商家消费以及互动抽奖提升景区二次传播</v-card-text>
          <v-card-text class="tip">4. AR技术修复文物进行场景复原传播景区历史文化</v-card-text>
        </div>
      </div>
    </v-card>
    <!-- 想在哪直播就在哪直播 -->
    <v-card flat class="h5model_list1">
      <v-card-title class="justify-center font-size-20 color-333 pa-0 mt-6 line-height-28">VR直播</v-card-title>
      <div class="h5-public-title">VR LIVE BROADCAST</div>
      <v-card-text class="py-0 mb-2 px-8 mt-2 text-align-center">虚拟IP，语音互动，自定义720VR场景</v-card-text>
      <div class="px-3">
        <v-row class="justify-center mt-0">
          <v-col v-for="(v,k) in modelList4" :key="k" cols="4" class="pa-0">
            <div class="model_item">
              <img class="item_img" :src='v.icon' />
              <div class="item_info">
                <v-card-title class="justify-center reset-title pt-2 font-size-16 color-333 font-weight-300">{{ v.title }}</v-card-title>
                <v-card-text class="reset-text reset-title font-size-12 color-999">{{ v.text }}</v-card-text>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-app>
</template>
<script>
import contentData from '@/static/contentData.js';
export default {
  name: 'h5saasMetaverse',
  components: {
  },
  data() {
    return {
      data: contentData.saasMetaverse,
      tab: 0,
      tab1: 0,
      modelList1: [
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-saas-f1.png",
          title: "技术保障",
          text: "AR/AR/VR技术的沉淀，AI技术的革新让产品表现更强劲"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-saas-f2.png",
          title: "海量素材",
          text: "VR全景，3D模型，无需准备即可快速生成内容"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-saas-f3.png",
          title: "资源整合",
          text: "整合头部资源，助力元宇宙空间的运营"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-saas-f4.png",
          title: "完善服务",
          text: "不仅提供工具，也提供完善的定制服务，满足个性化需求"
        }
      ],
      modelList4: [
        {
          icon: "https://h5.ophyer.cn/official_website/other/saasVR1.png",
          title: "虚拟形象",
          text: "定制属于你的数字人"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/other/saasVR2.png",
          title: "VR场景",
          text: "720沉浸式体验"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/other/saasVR3.png",
          title: "多人联动",
          text: "可同时容纳多人在线"
        }
      ],
      modelList2: [
        {
          tit: '3D环物内容编辑',
          img: "https://h5.ophyer.cn/official_website/other/saasEdit-01.png",
          text: "支持gltf、glb、fbx、obj多种格式的模型素材，支持随意调整模型视角、修改模型背景"
        },
        {
          tit: '全景图编辑',
          img: "https://h5.ophyer.cn/official_website/other/saasEdit-02.png",
          text: "支持场景管理、多样式热点、遮罩、沙盘、特效、自定义品牌/控件、一键分享功能"
        },
        {
          tit: '全景视频编辑',
          img: "https://h5.ophyer.cn/official_website/other/saasEdit-03.png",
          text: "支持多种规格清晰度视频，在视频内部任意嵌入热点，还可导出APK/控件、一键分享功能"
        },
        {
          tit: '景深漫游编辑',
          img: "https://h5.ophyer.cn/official_website/other/saasEdit-04.png",
          text: "不仅支持简单的平面VR图还支持基于3D场景制作包含空间信息的景深图组，生成可对外分享或在VR头显上体验的景深漫游作品"
        },
      ],
      modelList3: [
        {
          img: "https://h5.ophyer.cn/official_website/icon/saas-fun-01.png",
          title: "趣味互动内容",
          text: "海量模版一键套\n轻松生成趣味互动内容"
        },
        {
          img: "https://h5.ophyer.cn/official_website/icon/saas-fun-02.png",
          title: "丰富插件",
          text: "灵活多变的插件\n满足更多场景需求"
        },
        {
          img: "https://h5.ophyer.cn/official_website/icon/saas-fun-03.png",
          title: "多场景运营",
          text: "线上/线下强势引流\n助力运营转化"
        },
        {
          img: "https://h5.ophyer.cn/official_website/icon/saas-fun-04.png",
          title: "实时数据报告",
          text: "实时监控传播效果\n支持多维度分析"
        }
      ],
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    handleTabBtn: function (type){
      const maxTab = this.data.wrap2.list.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.warp2 {
  .box {
    width: 1024px;
    margin: 50px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
      object-fit: cover
    }
  }
  .right {
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    padding-top: 30px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .sub{
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
      margin-top: 16px;
    }
    .line{
      width: 68px;
      height: 4px;
      background: #000000;
      margin-top: 5px;
    }
    .tip {
      margin: 10px 0;
      font-size: 16px !important;
      color: rgb(102, 102, 102) !important;
    }
    .text {
      font-size: 14px;
      line-height: 26px;
    }
  }
}
::v-deep .warp3 {
  .box {
    min-width: 335px;
  }
  .v-card__title{
    padding: 24px;
  }
  .right{
     margin: 0 20px;
    .v-image__image{
      border-radius: 10px;
    }
  }
  .left {
    text-align: start;
    margin-right: 20px;
    margin-bottom: 24px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-size: 18px !important;
      padding: 15px 20px 5px !important;
    }
    .tip {
      margin: 0px 0;
      padding-left: 20px;
      
      font-size: 13px !important;
      color: rgb(102, 102, 102) !important;
      &:last-child{
        margin:  0;
      }
    }
    .text {
      font-size: 14px;
      line-height: 23px;
    }
  }
}
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    margin-top: -100px;
    .banner-text {
      p:nth-child(1) {
        font-size: 82px;
        //font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #fff;
        line-height: 88px;
      }
      p:nth-child(2) {
        margin-top: 25px;
        font-size: 24px;
        font-weight: 100 !important;
        color: #fff;
      }
      .banner-btn {
        margin-top: 90px;
        width: 200px;
        height: 60px;
        background: #0568FD;
        border-radius: 35px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  @media (min-width: 960px) {
    .container {
      max-width: 1200px !important;
    }
  }
  
}
.warps {
  margin: 0 auto;
  text-align: center;
}


.h5model_list1 {
  width: 100%;
  position: relative;
  .model_item{
    width: 100%;
    background: #FFFFFF;
    border-radius: 16px;
    padding-bottom: 30px;
    text-align: center;
    .item_img{
      width: 80px;
      height: 80px;
      object-fit: contain;
    }
    .item_info{
      margin-top: -10px;
    }
  }
}
.h5model_list2{
  width: 100%;
  position: relative;
  .model_item{
    width: 100%;
    .item_img{
      width: 100%;
      object-fit: contain;
      border-radius: 10px;
    }
    .item_info{
      margin-top: 8px;
      //padding: 0 16px;
      font-size: 14px;
      color: #333333;
      line-height: 1.2;
      text-align: center;
    }
  }
}
.h5model_list3{
  width: 100%;
  position: relative;
  .model_item{
    width: 100%;
    min-height: 220px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 0 10px;
    text-align: center;
    .item_img{
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
    .item_info{
      margin-top: 8px;
    }
  }
}
.h5model_list4{
  width: 100%;
  position: relative;
  .model_item{
    width: 100%;
    min-height: 220px;
    border-radius: 16px;
    padding: 0 10px;
    text-align: center;
    .item_img{
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
    .item_info{
      margin-top: 10px;
    }
  }
}

</style>
